import React, { Component } from "react"
import { Link, graphql, StaticQuery } from 'gatsby';
import WordMarque from '../components/WordMarque';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import '../styles/components/error.scss';

class NotFoundPage extends React.Component {

  render() {

    return (
      <>
        <StaticQuery 
          query={graphql`
          query Error {
            wp {
              generalSettings {
                title
                description
              }
            }
          }
          `}
          render={(error) => (
            <>
              <Layout>
                <SEO
                  scheme="error scheme:primary"
                  siteTitle={ error.wp.generalSettings.title }
                  siteDescription={ error.wp.generalSettings.description }
                  title={ "404" } 
                />

                <div className={ 'text:section error-content' }>
                  <div className={ 'flex' }>
                    <h2>404</h2>
                    <p>Page not found</p>
                    <WordMarque />
                  </div>
                </div>
              </Layout>
            </>
          )}
        />
      </>
    );

  }

}

export default NotFoundPage;

